@import "./sass/vendor/bootstrap.scss";
@import './sass/vendor/css/font-awesome.min.css';

@import "./sass/abstracts/mixins";
@import "./sass/abstracts/variables";
@import "./sass/abstracts/helpers";

@import "./sass/base/base";
@import "./sass/base/typography";

@import "./sass/components/buttons";

@import "./sass/layout/footer";
@import "./sass/layout/sections";

@import "./sass/sections/about";
@import "./sass/sections/contact";
@import "./sass/sections/hero";
@import "./sass/sections/projects";


@import './sass/abstracts/mixins';
@import './sass/abstracts/variables';
@import './sass/abstracts/helpers';

@import './sass/base/base';
@import './sass/base/typography';

@import './sass/components/buttons';

@import './sass/layout/footer';
@import './sass/layout/sections';

@import './sass/sections/about';
@import './sass/sections/contact';
@import './sass/sections/hero';
@import './sass/sections/projects';
@import './sass/sections/skills';


