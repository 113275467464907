#skills {
  background-color: $primary-color;
  background-image: linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  color: $white-color;
  height: 100%;
  border-top: 0px;
  padding: 20rem 0;
  //-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

  @include respond(tab-land) {
    //padding: 10% 0;
    height: 100%;
    -webkit-clip-path: none;
    clip-path: none;
  }

  @include respond(phone) {
    padding: 20rem 0;
  }

  #skills-accordion { 
    .accordion-item {
      .accordion-header{
        .accordion-button{
          font-size: 2.4rem;
        }
      }
      .accordion-collapse{
        .accordion-body{
          display: flex;
          flex-wrap: wrap;
          justify-content: left;
          #accordion-subtitle {
            font-size: 2.0rem;
          }
          .skill-list {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: left;
          }
          .ind-skill {
            display: flex;
            flex-direction: column;
            font-size: 1.6rem;
            margin: 1.5rem;
            @include respond(phone) {
              padding-right: 1rem;
              flex-direction: row;
              justify-content: flex-start;
              width: 40%;
              align-items: flex-end;
            }
  
            .skill-img-container {
              height: 60px;
              width: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              align-self: center;
              /*
              padding-bottom: 1rem;
              */
              @include respond(phone) {
                padding-bottom: 0;
                padding-right: 1rem;
              }
  
              .skill-image-wrapper{
                #skill-image{
                  width: auto;
                  height: auto;
                  max-width: 55px;
                  max-height: 55px; 
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
    }
  }
}
