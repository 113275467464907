// MEDIA QUERY MANAGER
/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

1em = 16px

ORDER: Base + Typography > General Layout + Grid > Page Layout > Components

*/
@mixin respond($breakpoint) {
  // Phone-xs
  @if $breakpoint == phone-xs {
    @media (max-width: 20em) {
      @content;
    } //0 - 320px
  }
  // Phone
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } //0 - 600px
  }
  // Table Portrait
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } //900px
  }
  // Table Landscape
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } //1200px
  }
  // Big Desktop
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } //1800px +
  }
}

// Border White Mixin
@mixin section-borders {
  border: 1.5rem solid #fff;
  border-top: 0;
  border-bottom: 0;
}
