#hero {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  border-bottom: 0px;
  background: $white-color;
  font-weight: 400;
  color: $dark-blue-text;
  padding: 0rem 5.6rem;
  margin-bottom: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  // If you want to set a background image on the hero section, uncomment these with yout custom image

  /* background: url("/src/assets/[your-image].png");
  background-position: center;
  background-size: cover; */

  nav {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;

    @include respond(phone) {
      justify-content: space-evenly;
      width: 100vw;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $white-color;
      font-size: 3rem;
      width: 5rem;
      height: 5rem;
      margin: $default-font-size $default-font-size;
      transition: all 0.2s ease-in;

      i {
        color: $secondary-color;
      }

      &:hover {
        transform: translateY(-2px);
      }
    }
  }

  @include respond(phone) {
    padding: 0rem $default-font-size;
  }

  .hero-text {
    font-weight: 700;
    text-align: left;
    font-size: 4.8rem;
    line-height: 1;

    #hero-title{
      font-size: 6rem;
      margin-bottom: 10px;
    }
    #hero-subtitle{
      font-size: 4.8rem;
      @include respond(tab-land) {
        font-size: 4rem;
      }
      @include respond(tab-port) {
        font-size: 3.2rem;
      }
      @include respond(phone) {
        font-size: 3.0rem;
      }
      @include respond(phone-xs) {
        font-size: 2.8rem;
      }
    }

  }

  .hero-cta {
    display: flex;
    flex-wrap: wrap;

    @include respond(tab-port) {
      justify-content: center;
    }
    @include respond(phone) {
      flex-direction: column;
      align-items: center;
    }

    .cta-btn--hero {
      @include respond(phone) {
        width: 40vw;
      }
    }

    & a {
      font-size: 2.4rem;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }
}

#hero-social-links {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 1.6rem;
  @include respond(tab-port) {
    justify-content: center;
  }

  a {
    color: $primary-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //color: $white-color;
    font-size: 3rem;
    width: 5rem;
    height: 5rem;
    margin: $default-font-size $default-font-size;
    transition: all 0.2s ease-in;

    &:hover {
      transform: translateY(-2px);
    }
  }

  @include respond(tab-port) {
    justify-content: center;
  }
}