html {
  font-size: 62.5%;
}

body {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.box {
  --mask:
    radial-gradient(10.08vh at 50% 13.75vh,#000 99%,#0000 101%) calc(50% - 10vh) 0/20vh 51% repeat-x,
    radial-gradient(10.08vh at 50% -8.75vh,#0000 99%,#000 101%) 50% 5vh/20vh calc(51% - 5vh) repeat-x,
    radial-gradient(10.08vh at 50% calc(100% - 13.75vh),#000 99%,#0000 101%) 50% 100%/20vh 51% repeat-x,
    radial-gradient(10.08vh at 50% calc(100% + 8.75vh),#0000 99%,#000 101%) calc(50% - 10vh) calc(100% - 5vh)/20vh calc(51% - 5vh) repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
}