#contact {
  background-image: $white-color;
  height: 100%;
  margin-top: -5rem;
  // margin-bottom: -1px;
  padding: 20rem 0 10rem 0;
  // color: $white-color;
  color: $dark-blue-text;

  -webkit-clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);

  @include respond(tab-land) {
    padding: 10rem 0;
    clip-path: none;
    margin-top: 15rem;
    -webkit-clip-path: none;
  }

  .contact-wrapper {
    margin-top: 3.2rem;
    padding: 0 2rem;
    backface-visibility: hidden;

    &__text {
      margin-bottom: 2.5rem;
    }

    &__text,
    a {
      font-size: 2.4rem;

      // Responsive for contact text
      @include respond(phone) {
        font-size: 2rem;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.6rem;

      button {
        font-size: 2.4rem;
        margin-top: 1rem;
      }

      .input {
        max-width: 800px;
        width: 100%;
        margin-bottom: 1rem;
        border-radius: 2px;
      }

      input, textarea {
        padding: 8px 12px;
        border-width: 1px;
      }

      .contact-label {
        max-width: 800px;
        width: 100%;
        text-align: left;
      }
    }
  }
}
