// COLORS
$primary-color: #5433FF;
$secondary-color: #20BDFF;

$white-color: #fff;

$dark-grey: #333333;

$green-grey: #43555293;

$dark-blue-text: #272341; // For Headings

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 2.5rem;
