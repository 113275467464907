#projects {
  background-color: $white-color;
  color: $dark-blue-text;
  margin-top: -15rem;
  padding: 18rem 0 10rem 0;

  //-webkit-clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
  //clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);

  margin-bottom: -15rem;

  @include respond(tab-land) {
    margin-top: 0;
    padding: 10rem 0 5rem 0;
    -webkit-clip-path: none;
    clip-path: none;
  }

  .category-subtitle-line{
    height: 2px;
    border-width: 0;
    color: black;
    background-color: black;
    opacity: 1.0;
  }
  .category-subtitle {
    font-size: 3rem;
    font-weight: bold;
    margin: 25px 0 25px 0;
    //text-align: left;
  }
  .category-subtitle-note {
    margin: 15px auto;
    width: 80%;
  }


  #project-date-list {
    margin-bottom: 1rem;
    #project-date {
      margin: 0;
    }
  }

  #project-img {
    max-height: 500px
  }

  .project-wrapper {
    margin-bottom: 15rem;

    @include respond(phone) {
      margin-bottom: 0rem;
    }

    .row {
      margin-bottom: 8rem;

      @include respond(phone) {
        margin-bottom: 4rem;
      }
    }

    &__text {
      text-align: left;

      @include respond(phone) {
        margin-bottom: 2.5rem !important;
      }
      @include respond(tab-land) {
        margin-bottom: 4.8rem;
      }

      p {
        font-size: 1.6rem;
      }
      .italics {
        font-style: italic;
        font-weight: normal;
      }

      .note {
        font-size: 1.3rem;
      }

      .bold {
        font-weight: bold;
      }

      .tech-stack {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        .tech {
          border: 1px solid $dark-blue-text;
          padding: 4px;
          margin: 2px;
          font-size: 1.2rem;
          font-weight: bold;
          color: $white-color;
          background-color: $primary-color;
        }
      }

      #project-note{
        font-size: 1.4rem;
        font-style: italic;
      }

      &-title {
        font-weight: bold;
        margin-bottom: 1rem;
        font-size: 2rem; //font-size: $mid-font-size;
      }

      & p > a {
        color: $secondary-color;
      }
    }

    &__image {
      width: 100%;
      margin: 0 auto;

      @include respond(tab-land) {
        width: 100%;
        margin: 0;
      }

      & .thumbnail {
        border: none;
        transition: all 0.2s ease-out;
        transition: 0.5s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
          0.5s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
      }
    }
  }
}
